import axios from './axios.service'

const method = 'permissions'

class RoleService {
  async getAll(queryData) {
    try {
      const response = await axios.get(`/${method}`, {
        params: { ...queryData }
      })
      return response.data
    } catch (error) {
      return error
    }
  }
}

export default new RoleService()
