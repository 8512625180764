<template>
  <div v-if="password" class="password-requirements-wrapper">
    <CAlert color="secondary" class="password-requirements">
      <b>Requerimentos mínimos da senha</b>
      <ul>
        <li
          v-if="passwordConfig.minlength"
          :class="{ passed: passwordValidations.minlength }"
        >
          Minímo de {{ passwordConfig.minlength }} caracteres
        </li>

        <li
          v-if="passwordConfig.uppercase"
          :class="{ passed: passwordValidations.uppercase }"
        >
          Mínimo 1 letra maiúscula
        </li>

        <li
          v-if="passwordConfig.lowercase"
          :class="{ passed: passwordValidations.lowercase }"
        >
          Mínimo 1 letra minúscula
        </li>

        <li
          v-if="passwordConfig.digits"
          :class="{ passed: passwordValidations.digits }"
        >
          Mínimo 1 número
        </li>

        <li
          v-if="passwordConfig.specialCharacter"
          :class="{
            passed: passwordValidations.specialCharacter
          }"
        >
          Mínimo 1 caracter especial
        </li>
      </ul>
    </CAlert>
  </div>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      default: ''
    },

    // Levels: 1 (low), 2 (regular) and 3 (high)
    level: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      passwordConfig: {
        minlength: false,
        uppercase: false,
        lowercase: false,
        digits: false,
        specialCharacter: false
      },
      passwordValidations: {}
    }
  },

  watch: {
    password: {
      handler() {
        this.checkPasswordStrength()
      },
      deep: true
    }
  },

  created() {
    this.setConfig()
  },

  methods: {
    setConfig() {
      // Levels: 1 (low), 2 (regular) and 3 (high)
      if (this.level === 1) {
        this.passwordConfig = {
          minlength: 6,
          uppercase: false,
          lowercase: false,
          digits: false,
          specialCharacter: false
        }
      } else if (this.level === 2) {
        this.passwordConfig = {
          minlength: 8,
          uppercase: true,
          lowercase: true,
          digits: false,
          specialCharacter: false
        }
      } else if (this.level === 3) {
        this.passwordConfig = {
          minlength: 12,
          uppercase: true,
          lowercase: true,
          digits: true,
          specialCharacter: true
        }
      }
    },

    checkPasswordStrength() {
      const password = this.password
      const input = document.querySelector('input[name="password"]')

      if (input && password) {
        const passwordHasMinlength = new RegExp(
          `(?=(.*).{${this.passwordConfig.minlength},})`
        )
        const passwordHasUppercase = new RegExp(/(?=(.*[A-Z]))/)
        const passwordHasLowercase = new RegExp(/(?=(.*[a-z]))/)
        const passwordHasDigits = new RegExp(/(?=(.*[0-9]))/)
        // eslint-disable-next-line
        const passwordHasSpecialCharacter = new RegExp(
          // eslint-disable-next-line no-useless-escape
          /(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])/
        )

        if (this.passwordConfig.minlength) {
          this.passwordValidations.minlength =
            passwordHasMinlength.test(password)
        }

        if (this.passwordConfig.uppercase) {
          this.passwordValidations.uppercase =
            passwordHasUppercase.test(password)
        }

        if (this.passwordConfig.lowercase) {
          this.passwordValidations.lowercase =
            passwordHasLowercase.test(password)
        }

        if (this.passwordConfig.digits) {
          this.passwordValidations.digits = passwordHasDigits.test(password)
        }

        if (this.passwordConfig.specialCharacter) {
          this.passwordValidations.specialCharacter =
            passwordHasSpecialCharacter.test(password)
        }

        const validationsHits = Object.keys(this.passwordValidations).reduce(
          (acc, key) => {
            const result = (acc += this.passwordValidations[key] ? 1 : 0)
            return result
          },
          0
        )

        const validationHitsPercentage =
          (validationsHits * 100) / Object.keys(this.passwordValidations).length

        if (validationHitsPercentage !== 100) {
          input.setCustomValidity(
            'Sua senha não está de acordo com os requerimentos mínimos de criação'
          )
        } else {
          input.setCustomValidity('')
        }

        return validationHitsPercentage
      } else {
        input.setCustomValidity('')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password-requirements-wrapper {
  margin: 20px 0px;

  .password-requirements {
    margin-bottom: 0px;

    b {
      font-size: 14px;
      display: inline-block;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        padding: 5px 0px;
        color: $secondary-400;

        &:before {
          content: '\f057';
          font-family: 'Font Awesome 5 Pro';
          font-style: normal;
          font-weight: 400;
          margin-right: 5px;
        }

        &.passed {
          color: $success;

          &:before {
            content: '\f058';
          }
        }
      }
    }
  }
}
</style>
