import axios from './axios.service'

const method = 'roles'

class RoleService {
  async getAll(queryData) {
    try {
      const response = await axios.get(`/${method}`, {
        params: { ...queryData }
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async getRoleInformation(id) {
    try {
      const response = await axios({
        method: 'get',
        url: `/${method}/${id}`
      })
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async deleteRole(rowClicked) {
    try {
      const response = await axios.delete(`/${method}/${rowClicked.id}`)
      return response
    } catch (error) {
      return error
    }
  }

  async storeRole(data) {
    try {
      const response = await axios({
        method: 'post',
        url: `/${method}`,
        data: {
          ...data
        }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateRole(id, data) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/${id}`,
        data: {
          ...data
        }
      })
      return response
    } catch (error) {
      return error.response
    }
  }
}

export default new RoleService()
